<!-- eslint-disable max-len -->
<template>
    <div class="invention-apply">
        <div
            v-if="isFinish"
            class="deadline"
        >
            {{ $t('InventionApply.Invention.deadline') }}
        </div>
        <!-- <div
            v-if="false"
            class="deadline"
        >
            報名已截止
        </div> -->
        <div
            v-else
            class="web-content"
        >
            <div class="process-structure">
                <div class="apply-process-body">
                    <div class="process-page-title-row">
                        <div class="process-page-title">
                            {{ $t('InventionApply.Invention.title') }}
                        </div>
                    </div>
                    <v-stepper
                        alt-labels
                        :value="nowStep"
                        class="stepper"
                    >
                        <v-stepper-header>
                            <v-stepper-step
                                :color="stepColor(1)"
                                :complete="nowStep > 1"
                                step="1"
                            >
                                {{ $t('InventionApply.Invention.stepper[0]') }}
                            </v-stepper-step>

                            <v-divider />

                            <v-stepper-step
                                :color="stepColor(2)"
                                :complete="nowStep > 2"
                                step="2"
                            >
                                {{ $t('InventionApply.Invention.stepper[1]') }}
                            </v-stepper-step>

                            <v-divider />

                            <v-stepper-step
                                :color="stepColor(3)"
                                :complete="nowStep > 3"
                                step="3"
                            >
                                {{ $t('InventionApply.Invention.stepper[2]') }}<br> {{ $t('InventionApply.Invention.stepper[3]') }}
                            </v-stepper-step>
                            <v-divider />
                            <v-stepper-step
                                :color="stepColor(4)"
                                :complete="nowStep > 4"
                                step="4"
                            >
                                {{ $t('InventionApply.Invention.stepper[4]') }}<br>{{ $t('InventionApply.Invention.stepper[5]') }}
                            </v-stepper-step>
                            <v-divider />
                            <v-stepper-step
                                :color="stepColor(5)"
                                :complete="nowStep > 5"
                                step="5"
                            >
                                {{ $t('InventionApply.Invention.stepper[6]') }}
                            </v-stepper-step>
                        </v-stepper-header>
                    </v-stepper>
                    <!-- Child Router -->
                    <router-view />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import API from '@/api';
import _ from 'lodash';

export default {
    components: {},
    data() {
        return {
            session: '',
        };
    },
    computed: {
        ...mapGetters('mAuth', ['isLogin']),
        applyId() {
            return this.$route.params.id;
        },
        nowStep() {
            switch (this.$route.name) {
                case 'InventionTeamData':
                    return 1;
                case 'InventionProposal':
                    return 2;
                case 'InventionTerms':
                    return 3;
                case 'InventionConfirm':
                    return 4;
                case 'InventionComplete':
                    return 5;
                default:
                    return 1;
            }
        },
        isFinish() {
            return false;
        },
    },
    created() {
        this.init();
    },
    mounted() { },
    methods: {
        ...mapMutations('mNotification', ['setAlert']),
        ...mapMutations('mLoading', ['setLoading']),
        ...mapActions('mInventionApply', ['loadApply']),
        async init() {
            try {
                const res = await API.Setting.PublicInfo();
                this.session = res.data.InventionSession.session;
            } catch (e) {
                this.handleError({
                    message: e || this.$t('alertMessage.session-error'),
                    color: 'error',
                });
            }
            const check = await this.checkUserInventionApply();
            console.log('check', check);
            if (!this.isLogin) {
                this.setAlert({
                    message: this.$t('alertMessage.login'),
                    color: 'primary',
                });
                this.$router.replace({ name: 'Login' });
                return;
            }
            if (!check) {
                this.setAlert({
                    message: this.$t('alertMessage.Sign-up'),
                    color: 'primary',
                });
                this.$router.push({ name: 'UserInvention' });
                return;
            }
            if (this.applyId === 'new') {
                return;
            }
            this.loadApply(this.applyId);
        },
        stepColor(val) {
            if (this.nowStep >= val) {
                return '#F97E2B';
            }
            return '';
        },
        async checkUserInventionApply() {
            if (this.applyId !== 'new') {
                return true;
            }
            this.setLoading(true);
            const res = await this.getUserInventionList();
            const inventionList = res.data.raw;
            const applyList = _.filter(inventionList, (o) => o.session === this.session);
            this.setLoading(false);
            if (applyList.length > 0) {
                return false;
            }
            return true;
        },
        async getUserInventionList() {
            return API.InventionApply
                .PrivateList({
                    params: {
                        limit: 10, // default: 10 可不帶
                        offset: 0, // 從第幾比開始取
                    },
                });
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/apply.scss';

.invention-apply {
  display: flex;
  justify-content: center;
}

.deadline{
  padding: 50px 10px;
}
</style>
